/* ==========================================================================
   Main
   ========================================================================== */

.main {
	position: relative;
	background-color: white;

	&__shirehorse {
		position: relative;
		background: #fff;

		&__image {
			position: absolute;
			margin-bottom: 0;
			top: 0;
			left: 0;
			bottom: 0;
			right: 0;

			> img {
				width: 100%;
				height: 100%;
				object-fit: cover;
			}

			@include media-breakpoint-down('md') {
				margin-bottom: 2rem;
				position: relative;
			}
		}

		&__title {
			position: relative;
			margin-bottom: 3.5rem;
			padding-top: 16rem;
			font-family: Champignon, sans-serif;
			font-size: 80px;
			font-weight: normal;
			line-height: 0.4;
			z-index: 1;

			@include media-breakpoint-down('md') {
				margin-bottom: 2rem;
				padding-top: 4rem;
				font-size: 2.5rem;
			}

			@media (min-width: 1900px) {
				padding-top: 15vw;
			}
		}

		&__paragraph01 {
			max-width: 390px;
			position: relative;
			padding-bottom: 15rem;
			z-index: 1;

			@include media-breakpoint-down('md') {
				padding-bottom: 2rem;
			}

			@media (min-width: 1900px) {
				padding-bottom: 15vw;
			}
		}

		&__paragraph02 {
			margin-bottom: 0;
			position: relative;
			padding: 13rem 0 10rem 5rem;
			z-index: 1;

			@include media-breakpoint-down('md') {
				padding: 2rem 0 4rem 0;
			}

			@media (min-width: 1900px) {
				padding: 13vw 0 13vw 5rem;
			}
		}

		&__bcg {
			width: 100%;
			height: auto;
			position: absolute;
			right: 0;
			bottom: -4rem;
		}

		&__bcg-castle {
			width: 100%;
			height: auto;
			position: absolute;
			left: 0;
			bottom: -4rem;
		}
	}

	&__mastiff {
		position: relative;
		padding-top: 9rem;
		background: #eaeae3;

		@include media-breakpoint-down('md') {
			padding-top: 4rem;
		}

		&__title {
			margin-bottom: 2.5rem;
			padding-left: 5rem;
			font-size: 80px;
			font-family: Champignon, sans-serif;
			font-weight: normal;

			@include media-breakpoint-down('md') {
				font-size: 2.5rem;
				padding-left: 0;
				margin-bottom: 2rem;
			}
		}

		&__paragraph01 {
			padding-bottom: 4rem;
			padding-left: 5rem;

			@include media-breakpoint-down('md') {
				padding-left: 0;
				padding-bottom: 2rem;
			}
		}

		&__image {
			position: absolute;
			margin-bottom: 0;
			top: 0;
			left: 0;
			bottom: 0;
			right: 0;

			> img {
				width: 100%;
				height: 100%;
			}

			@include media-breakpoint-down('md') {
				margin-bottom: 2rem;
				position: relative;
			}
		}

		&__paragraph02 {
			position: relative;
			padding-top: 4rem;
			z-index: 1;

			@include media-breakpoint-down('md') {
				padding-top: 2rem;
			}

			@media (min-width: 1400px) {
				padding-top: 8vw;
			}
		}

		&__paragraph03 {
			position: relative;
			padding-bottom: 20rem;
			z-index: 1;

			@include media-breakpoint-down('md') {
				padding-bottom: 2rem;
			}

			@media (min-width: 1400px) {
				padding-bottom: 30vw;
			}
		}

		&__bcg {
			position: absolute;
			left: 0;
			bottom: -4rem;
		}
	}

	&__contact {
		padding-top: 8rem;
		padding-bottom: 5rem;
		background: #000;

		@include media-breakpoint-down('md') {
			padding-top: 2.5rem;
			padding-bottom: 3rem;
		}

		&__title {
			margin-bottom: 4rem;
			color: #fff;
			font-size: 80px;
			font-family: Champignon, sans-serif;
			font-weight: normal;
			text-align: center;

			@include media-breakpoint-down('md') {
				font-size: 2.5rem;
			}
		}

		&__form {
			@include media-breakpoint-up('md') {
				max-width: 750px;
				margin: 0 auto;
			}

			h4 {
				margin-bottom: 0;
				color: rgba($white, 0.58);
				font-weight: $font-weight-bold;
			}

			.form-group {
				display: flex;
				flex-direction: column-reverse;
			}

			.form-control {
				padding: 0.375rem 0;
				color: #fff;
				font-size: $font-size-sm;
				background: #000;
				border-width: 0 0 1px 0;
				border-color: #595959;

				&:focus {
					border-color: $primary;

					& + * {
						color: #fff;
					}
				}

				&:valid + * {
					color: #fff;
				}
			}

			textarea {
				resize: none;
			}

			.btn {
				margin-bottom: 5rem;
			}
		}

		.svg-icon {
			fill: #d3d4d3;
		}
	}

	&__map {
		#contact-map {
			height: 349px;
		}

		&__point > img {
			transform: translateY(-40px);
		}
	}

	.carousel,
	.carousel-inner {
		height: 100%;

		@include media-breakpoint-down('md') {
			height: 50vh;
		}
	}

	.carousel-item {
		position: absolute;
		top: 0;
		left: 0;
		bottom: 0;
		right: 0;

		> img {
			width: 100%;
			height: 100%;
			object-fit: cover;
		}
	}
}
