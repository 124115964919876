/* ==========================================================================
   Header
   ========================================================================== */

.header {
	position: fixed;
	width: 100%;
	height: 80px;
	z-index: 20;
	background-color: rgba($white, 0);

	@include media-breakpoint-up('lg') {
		transition: background 0.4s;
	}

	.navbar {
		height: 100%;
	}

	.navbar-brand > svg {
		fill: #fff;
		transition: all 0.4s;
	}

	.nav-item {
		display: flex;
		align-items: center;
	}

	.nav-link {
		display: flex;
		align-items: center;
		margin: 0 0.5rem;
		transition: opacity 0.2s;
		fill: #fff;
		transform: translateY(2px);

		@include media-breakpoint-up('sm') {
			visibility: hidden;
			opacity: 0;
		}
	}

	&--white {
		background-color: rgba($white, 1);
		box-shadow: 0 4px 12px 0 rgba(0, 0, 0, 0.05);

		.navbar-brand > svg {
			fill: #000;
		}

		.nav-link {
			visibility: visible;
			opacity: 1;
			fill: #000;

			&:hover {
				opacity: 0.6;
			}
		}
	}
}
