/* ==========================================================================
   Footer
   ========================================================================== */

.footer {
	position: relative;
	background: #fff;
	font-size: $font-size-sm;

	@include media-breakpoint-up('sm') {
		height: 80px;
	}

	&__copy {
		color: rgba(#000, 0.5);
	}

	.svg-icon {
		transition: opacity 0.2s;

		&:hover {
			opacity: 0.6;
		}
	}
}
