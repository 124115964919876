/* =============================================================================
	Hero
============================================================================= */

.hero {
	position: relative;
	width: 100%;
	$this: &;

	&__image {
		> img {
			max-width: 100%;
			height: auto;
		}

		@include media-breakpoint-up('lg') {
			position: absolute;
			top: 0;
			left: 0;
			bottom: 0;
			right: 0;
			margin-bottom: 0;

			> img {
				width: 100%;
				height: 100%;
				object-fit: cover;
			}
		}
	}

	&__overflow {
		position: absolute;
		width: 100%;
		height: 346px;
		background-image: linear-gradient(180deg, rgba(0, 0, 0, 0.57) 0%, rgba(0, 0, 0, 0) 100%);
		top: 0;

		@include media-breakpoint-down('xs') {
			height: 120px;
		}
	}

	&__title {
		display: flex;
		flex-direction: column;
		align-items: center;
		position: absolute;
		text-align: center;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
		color: #fff;

		> h1 {
			margin-bottom: 0;
			font-family: 'Champignon', sans-serif;
			font-size: 180px;
			font-weight: normal;
			white-space: nowrap;
			line-height: 0.6;

			@include media-breakpoint-down('lg') {
				font-size: 3rem;
			}

			@include media-breakpoint-down('md') {
				display: none;
			}
		}

		> h3 {
			letter-spacing: 3.3px;

			@include media-breakpoint-down('md') {
				font-size: 1rem;
			}
		}
	}

	&__social {
		position: absolute;
		top: 50%;
		left: 0;
		display: flex;
		justify-content: space-between;
		flex-direction: column;
		transform: translateY(-50%);

		@include media-breakpoint-down('md') {
			display: none;
		}

		&__link {
			width: 110px;
			display: flex;
			align-items: center;
			margin: 0.25rem 0 0.5rem 2.25rem;

			> span {
				display: flex;
				align-items: center;
				height: 40px;
				flex: 0 0 30px;
			}

			> h4 {
				margin-bottom: 0;
				color: #fff;
				text-transform: none;
				opacity: 0;
				transform: translateY(20px);
				transition: all 0.2s;
			}

			&:hover {
				text-decoration: none;

				> h4 {
					transform: translateY(0);
					opacity: 1;
				}
			}
		}
	}

	&__scroll {
		display: flex;
		flex-direction: column;
		align-items: center;
		position: absolute;
		z-index: 10;
		left: 50%;
		transform: translateX(-50%);
		bottom: 3rem;
		color: #fff;
		cursor: pointer;

		@include media-breakpoint-down('md') {
			display: none;
		}
	}
}
