@font-face {
	font-family: Champignon;
	src: url('../fonts/Champignon.woff') format('woff');
	src: url('../fonts/Champignon.woff2') format('woff2');
	font-weight: normal;
	font-style: normal;
}

// Bootstrap import
@import "../../node_modules/bootstrap/scss/functions";
@import "common/variables";
@import "../../node_modules/bootstrap/scss/mixins";
@import "../../node_modules/bootstrap/scss/root";
@import "../../node_modules/bootstrap/scss/reboot";
@import "../../node_modules/bootstrap/scss/type";
@import "../../node_modules/bootstrap/scss/images";
@import "../../node_modules/bootstrap/scss/code";
@import "../../node_modules/bootstrap/scss/grid";
@import "../../node_modules/bootstrap/scss/forms";
@import "../../node_modules/bootstrap/scss/buttons";
@import "../../node_modules/bootstrap/scss/custom-forms";
@import "../../node_modules/bootstrap/scss/nav";
@import "../../node_modules/bootstrap/scss/navbar";
@import "../../node_modules/bootstrap/scss/carousel";
@import "../../node_modules/bootstrap/scss/utilities";

// Components
@import "components/buttons";

//Mapbox
@import "~mapbox-gl/dist/mapbox-gl.css";

//Layouts
@import 'layouts/footer';
@import 'layouts/header';
@import 'layouts/hero';
@import 'layouts/main';

//Common
@import 'common/global';
