/* =============================================================================
	Buttons
============================================================================= */

.btn {
	display: flex;
	align-items: center;
	height: 47px;
	letter-spacing: 1.29px;

	> span {
		transform: translateY(1px);
	}
}
