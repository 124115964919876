/* =============================================================================
	Global
============================================================================= */

body {
  margin: 0 auto;
  overflow-x: hidden;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

br {
  @include media-breakpoint-down('xl') {
    display: none;
  }
}

p {
  @include media-breakpoint-up('xl') {
    max-width: 600px;
  }
}
